<template>
  <div class="app-wrapper wrapper-view">
    <div class="wrapper-title">高频词审核</div>
    <div class="content-head">
      <div class="head-btn">
        <!-- <div class="btn">上传核心词</div> -->
        <div class="btn" @click="handleAudited">审核通过</div>
      </div>
    </div>
    <div class="source-material-list" v-loading="isLoading">
      <div
        class="source-material-item"
        v-for="item in tableData"
        :key="item._id"
      >
        <div class="item-content">
          <div class="li"></div>
          <div class="li-text">{{ item._id['k'] }}</div>
        </div>
        <div class="item-opts">
          <div class="item-btn">
            <span @click="frequentKwtList(item._id['k'],item._id['u'] )">
              <i class="el-icon-eleme"></i>高频词({{ item.count }})</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { HTTP } from "../../../../../utils/request";

const URL = {
  taskDetail: "api/operation/material/audioSeedKwdList",
  audited: "api/operation/material/frequentKwdaudited",
};

export default {
  components: {
  },
  data() {
    return {
      id: this.$route.query?.id,
      tableData: [],
      edit: {
        show: false,
        data: null,
      },
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    async getList() {
      this.isLoading = true;
      const res = await HTTP({
        url: URL.taskDetail,
        method: "get",
        data: {
          id: this.id,
        },
      });
      this.tableData = res || [];
      this.isLoading = false;
    },
    frequentKwtList(k,u) {
      this.$rou;
          this.$router.push({
            name: "FrequentKeyWords",
            query: {
              k: k,
              u: u,
              t: this.id,
            },
          });
    },
    handleAudited() {
      this.$confirm("确认通过？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          HTTP({
            url: URL.audited,
            method: "post",
            data: {
              id: this.id,
            },
          }).then(() => {
            this.$message.success("审核成功");
            this.$rou;
            this.$router.push({
                name: "Examine",
                query: {},
            });
          });
        })
        .catch(() => {
          this.$message.info("已取消【审核】操作");
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.tip {
  display: flex;
  font-size: 14px;
  font-family: SourceHanSansCN-Regular-, SourceHanSansCN-Regular;
  font-weight: normal;
  color: #7c7f97;
  line-height: 24px;
  margin-top: 10px;
}
.source-material-list {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  border-left: 1px solid #e4e8fc;
  margin-top: 14px;
  .source-material-item {
    width: 25%;
    padding: 40px 20px;
    border-right: 1px solid #e4e8fc;
    border-bottom: 1px solid #e4e8fc;
    border-top: 1px solid #e4e8fc;
    .item-content {
      display: flex;
      align-items: center;
      justify-content: center;
      .li {
        width: 4px;
        height: 4px;
        border-radius: 50%;
        background-color: #7c7f97;
        margin-right: 10px;
      }
      .li-text {
        font-size: 16px;
        font-family: SourceHanSansCN-Regular-, SourceHanSansCN-Regular;
        font-weight: normal;
        color: #7c7f97;
      }
    }
    .item-opts {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .item-btn {
      text-align: center;
      font-size: 16px;
      font-family: SourceHanSansCN-Regular-, SourceHanSansCN-Regular;
      font-weight: normal;
      color: #7c7f97;
      margin-top: 20px;
    }
    .item-edit {
      &:hover {
        color: #ff7800;
        cursor: pointer;
      }
    }
    .item-remove {
      &:hover {
        color: #4e6bef;
        cursor: pointer;
      }
    }
    &:hover {
      background-color: #f6f6fd;
    }
  }
}
.content-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
  .head-btn {
    display: flex;
    align-items: center;
    .btn {
      width: 128px;
      height: 33px;
      background: #4e6bef;
      margin-right: 12px;
      font-size: 16px;
      font-family: SourceHanSansCN-Regular-, SourceHanSansCN-Regular;
      font-weight: normal;
      color: #ffffff;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 4px;
      cursor: pointer;
    }
  }
}
</style>
